import * as fromApp from './reducers/app.reducer';
import { createSelector } from '@ngrx/store';

// Selector functions
const brandsState = (state: fromApp.IAppState) => state.brandsState;
const brandUsersState = (state: fromApp.IAppState) => state.brandUsersState;
const brandDetailsState = (state: fromApp.IAppState) => state.brandDetailsState;
const shardsState = (state: fromApp.IAppState) => state.shardsState;
const feedsState = (state: fromApp.IAppState) => state.feedsState;
const feedLogsState = (state: fromApp.IAppState) => state.feedLogsState;
const feedLogState = (state: fromApp.IAppState) => state.feedLogState;
const feedFilesState = (state: fromApp.IAppState) => state.feedFilesState;
const feedFileState = (state: fromApp.IAppState) => state.feedFileState;
const loadingsState = (state: fromApp.IAppState) => state.loadingsState;
const templatesState = (state: fromApp.IAppState) => state.templatesState;
const templateDetailsState = (state: fromApp.IAppState) => state.templateDetailsState;
const assignmentTemplatesState = (state: fromApp.IAppState) => state.assignmentTemplatesState;
const assignmentTemplateDetailsState = (state: fromApp.IAppState) => state.assignmentTemplateDetailsState;
const templateAssignmentsState = (state: fromApp.IAppState) => state.templateAssignmentsState;

//// Brands
export const isAllBrandsLoaded = createSelector(
	brandsState,
	state => state.isAllLoaded
);

export const brandStatusChanging = createSelector(
	brandsState,
	state => state.brandStatusChanging
);

export const getBrands = createSelector(
	brandsState,
	state => state.brands
);

export const getBrandDetails = createSelector(
	brandDetailsState,
	state => state.brand
);

export const isBrandLoaded = createSelector(
	brandDetailsState,
	state => state.isAllLoaded
);

export const isNewBrandCreated = createSelector(
	brandsState,
	state => state.isNewBrandCreating
);

export const getUsersByBrand = (id: number) => createSelector(
	brandUsersState,
	state => state.brandUsers.find(x => x.brandId === id)
);

export const getCreatedBrand = createSelector(
	brandsState,
	state => state.brandId
);
export const getShards = createSelector(
	shardsState,
	state => state.shards
);

export const isShardsLoaded = createSelector(
	shardsState,
	state => state.isAllLoaded
);

//// Feeds
export const isAllFeedsLoaded = createSelector(
	feedsState,
	state => state.isAllLoaded
);

export const getFeeds = createSelector(
	feedsState,
	state => state.feeds
);

export const logsByFeedId = (id: number) => createSelector(
	feedLogsState,
	state => state.feedLogs.find(x => x.feedId === id)
);

export const getFeedLog = createSelector(
	feedLogState,
	state => state.feedLog
);

export const isFeedLogLoaded = createSelector(
	feedLogState,
	state => state.isFeedLogFetching
);

export const isFeedsLogLoaded = createSelector(
	feedLogsState,
	state => state.isFeedsLogFetching
);

export const isFeedFileLoaded = createSelector(
	feedFileState,
	state => state.isFeedFileFetching
);

export const isFeedsFilesLoaded = createSelector(
	feedFilesState,
	state => state.isFeedFilesFetching
);

export const filesByFeedId = (id: number) => createSelector(
	feedFilesState,
	state => state.feedFiles.find(x => x.feedId === id)
);

export const getFeedFile = createSelector(
	feedFileState,
	state => state.feedFile
);

export const isFeedClone = createSelector(
	feedsState,
	state => state.isFeedClone
);

export const getClonedFeed = createSelector(
	feedsState,
	state => state.feedId
);

//// Loadings
export const getIsLoading = createSelector(
	loadingsState,
	state => state.loadingStatuses.length > 0
);

//// Templates
export const getTemplates = createSelector(
	templatesState,
	state => state.templates
);

export const isAllTemplatesLoaded = createSelector(
	templatesState,
	state => state.isAllLoaded
);

export const isTemplateLoaded = createSelector(
	templateDetailsState,
	state => state.isAllLoaded
);

export const isTemplateAssignmentsLoaded = createSelector(
	templateAssignmentsState,
	state => state.isAllLoaded
);

export const isAssignmentTemplateLoaded = createSelector(
	assignmentTemplateDetailsState,
	state => state.isAllLoaded
);

export const isTemplateUpdated = createSelector(
	templateDetailsState,
	state => state.isTemplateUpdated
);

export const isAssignmentTemplateUpdated = createSelector(
	assignmentTemplateDetailsState,
	state => state.isTemplateUpdated
);


export const getCreatedTemplate = createSelector(
	templatesState,
	state => state.templateId
);

export const getCreatedAssignmentTemplate = createSelector(
	assignmentTemplatesState,
	state => state.assignmentTemplateId
);

export const isNewTemplateCreated = createSelector(
	templatesState,
	state => state.isNewTemplateCreating
);

export const isNewTemplateCreateFail= createSelector(
	templatesState,
	state => state.error
);

export const isTemplateUpdateFail= createSelector(
	templatesState,
	state => state.error
);

export const isTemplatesDeleted = createSelector(
	templatesState,
	state => state.isTemplatesDeleted
);

export const isTemplatesDeletedFail = createSelector(
	templatesState,
	state => state.error
);

export const isAssignmentTemplatesDeleted = createSelector(
	assignmentTemplatesState,
	state => state.isAssignmentTemplatesDeleted
);

export const isAssignmentTemplatesDeleteFail = createSelector(
	assignmentTemplatesState,
	state => state.error
);

export const getTemplateDetails = createSelector(
	templateDetailsState,
	state => state.template
);

export const getAssignmentTemplates = createSelector(
	assignmentTemplatesState,
	state => state.assignmentTemplates
);

export const getAssignmentTemplate = createSelector(
	assignmentTemplateDetailsState,
	state => state.template
);

export const getTemplateAssignments = createSelector(
	templateAssignmentsState,
	state => state.templateAssignments
);
