import { AppActions, AppActionTypes } from '../actions/admin-actions';
import { ActionType, ActionWithStatus } from '../actions/base-actions';
import { ActionReducerMap } from '@ngrx/store';
import { Brand, Feed, FeedFile, FeedLog, AssignmentTemplate, Shard, Template, TemplateAssignment, User } from 'domain/models';
import { AuthAction, AuthActions } from '../actions/auth-actions';
import { Guid } from 'domain/types';

export interface IAuthState {
	user: User | null;
	loading: boolean;
	loaded: boolean;
	errorMessage: string;
}

export interface IBaseState {
	isAllLoaded: boolean;
}

export interface IBrandState extends IBaseState {
	brandId: number;
	brands: Brand[];
	isNewBrandCreating: boolean;
	brandStatusChanging: boolean;
}

export interface IBrandDetailsState extends IBaseState {
	brand: any;
	isBrandFetching: boolean;
}

export interface IFeedState extends IBaseState {
	feedId: number;
	feeds: Feed[];
	isFeedClone: boolean;
}

export interface IShardState extends IBaseState {
	shards: Shard[];
}

export interface IBrandUsersState {
	brandUsers: any;
}

export interface IFeedLogsState {
	feedLogs: any;
	isFeedsLogFetching: boolean;
}

export interface IFeedLogState extends IBaseState {
	feedLog: any;
	isFeedLogFetching: boolean;
}

export interface IFeedFilesState {
	feedFiles: any;
	isFeedFilesFetching: boolean;
}

export interface IFeedFileState extends IBaseState {
	feedFile: any;
	isFeedFileFetching: boolean;
}

export interface ILoadingState {
	loadingStatuses: boolean[];
}

export interface ITemplatesState extends IBaseState {
	templateId: number;
	templates: Template[];
	isNewTemplateCreating: boolean;
	isTemplatesDeleted: boolean;
	error: undefined;
}

export interface ITemplateDetailsState extends IBaseState {
	isTemplateUpdated: boolean;
	template: any;
	isTemplateFetching: boolean;
}

export interface IAssignmentTemplatesState {
	isAssignmentTemplatesDeleted: boolean;
	assignmentTemplateId: Guid;
	assignmentTemplates: AssignmentTemplate[];
	error: undefined;
}

export interface ITemplateAssignmentsState extends IBaseState {
	templateAssignments: TemplateAssignment[];
}

export interface IAssignmentTemplateDetailsState extends IBaseState {
	isTemplateUpdated: boolean;
	template: any;
	isTemplateFetching: boolean;
}

export interface IAppState {
	brandsState: IBrandState;
	brandDetailsState: IBrandDetailsState;
	feedsState: IFeedState;
	shardsState: IShardState;
	brandUsersState: IBrandUsersState;
	feedLogsState: IFeedLogsState;
	feedLogState: IFeedLogState;
	feedFilesState: IFeedFilesState;
	feedFileState: IFeedFileState;
	loadingsState: ILoadingState;
	auth: IAuthState;
	templatesState: ITemplatesState;
	templateDetailsState: ITemplateDetailsState;
	assignmentTemplateDetailsState: IAssignmentTemplateDetailsState;
	assignmentTemplatesState: IAssignmentTemplatesState;
	templateAssignmentsState: ITemplateAssignmentsState;
	//brandStatusChangeState: IBrandStatusChangeState;
}

// Initial state
const initialBrandsState: IBrandState = {
	isAllLoaded: false,
	isNewBrandCreating: false,
	brandStatusChanging: false,
	brands: [],
	brandId: 0
};

export function brandsReducer(state: IBrandState = initialBrandsState, action: AppActions): IBrandState {
	switch (action.type) {
		case AppActionTypes.LoadAllBrandsSuccess:
			return state = { ...state, isAllLoaded: true, brands: action.brands };
		case AppActionTypes.LoadAllBrandsFail:
			return state = { ...state, isAllLoaded: false };
		case AppActionTypes.ProvisionNewBrand:
			return state = { ...state, isNewBrandCreating: true };
		case AppActionTypes.ProvisionBrandFail:
			return state = { ...state, isNewBrandCreating: false };
		case AppActionTypes.SetBrandStatus:
			return state = { ...state, brandStatusChanging: true };
		case AppActionTypes.SetBrandStatusSuccess: {
			const brand = { ...state.brands.find(b => b.id === action.brandId) };
			brand.active = !brand.active;
			return state = { ...state, brands: state.brands, brandStatusChanging: false };
		}
		case AppActionTypes.SetBrandStatusFail:
			return state = { ...state, brandStatusChanging: false };
		case AppActionTypes.ProvisionBrandSuccess: {
			const initialBrand = new Brand();

			initialBrand.name = action.brand.brandName;
			initialBrand.id = action.newBrand.brandId;
			initialBrand.resellerId = action.newBrand.resellerId;
			initialBrand.databaseName = action.newBrand.databaseName;
			initialBrand.useProductChargesHistoricity = action.newBrand.useProductChargesHistoricity;
			initialBrand.allowMultiplePaymentPortals = action.newBrand.allowMultiplePaymentPortals;
			initialBrand.useAutoBilling = action.newBrand.useAutoBilling;
			initialBrand.kleberIntegrationCountry = action.newBrand.kleberIntegrationCountry;
			initialBrand.active = true;
			state = {
				...state,
				brands: state.brands.concat(initialBrand),
				brandId: action.newBrand.brandId,
				isNewBrandCreating: false
			};
			break;
		}
		case AppActionTypes.ClearCreatedBrand:
			state = { ...state, brandId: -1 };
			break;
		case AppActionTypes.UpdateBrandSuccess: {
			state = { ...state, isAllLoaded: false };
			break;
		}
	}

	return state;
}

const initialBrandDetailsState: IBrandDetailsState = { isAllLoaded: false, brand: Brand, isBrandFetching: false };

export function brandDetailsReducer(state: IBrandDetailsState = initialBrandDetailsState, action: AppActions): IBrandDetailsState {
	switch (action.type) {
		case AppActionTypes.LoadBrand:
			return state = {
				...state,
				isAllLoaded: true
			};
		case AppActionTypes.LoadBrandSuccess:
			return state = {
				...state,
				isAllLoaded: false,
				brand: action.brand
			};
		case AppActionTypes.LoadBrandFail:
			return state = {
				...state,
				isAllLoaded: false
			};
	}
	return state;
}

const initialBrandUsersState: IBrandUsersState = { brandUsers: [] };

export function brandUsersReducer(state: IBrandUsersState = initialBrandUsersState, action: AppActions): IBrandUsersState {
	switch (action.type) {
		case AppActionTypes.LoadUsersByBrandSuccess:
			return state = {
				...state,
				brandUsers: state.brandUsers.concat({ brandId: action.brandId, brandUsers: action.brandUsers })
			};
	}

	return state;
}

const initialITemplateAssignmentsReducer: ITemplateAssignmentsState = {
	templateAssignments: [],
	isAllLoaded: false,
};

export function templateAssignmentsReducer(state: ITemplateAssignmentsState = initialITemplateAssignmentsReducer,
	action: AppActions): ITemplateAssignmentsState {

	switch (action.type) {
		case AppActionTypes.LoadTemplateAssignmentsSuccess:
			return state = { ...state,
				templateAssignments: action.templateAssignments,
				isAllLoaded: true };
	}

	return state;
}

const initialIAssignmentTemplatesState: IAssignmentTemplatesState = {
	assignmentTemplates: [],
	assignmentTemplateId: Guid.empty,
	isAssignmentTemplatesDeleted: false,
	error: undefined
};

export function assignmentTemplatesReducer(state: IAssignmentTemplatesState = initialIAssignmentTemplatesState,
	action: AppActions): IAssignmentTemplatesState {

	switch (action.type) {
		case AppActionTypes.LoadAssignmentTemplatesSuccess:
			return state = { ...state, assignmentTemplates: action.assignmentTemplates };
		case AppActionTypes.AddAssignmentTemplateSuccess:
			return state = { ...state, assignmentTemplateId: action.assignmentTemplateId };
		case AppActionTypes.ClearCreatedAssignmentTemplate:
			return state = { ...state, assignmentTemplateId: Guid.empty };
		case AppActionTypes.DeleteAssignmentTemplateFail:
			return state = { ...state, error: action.error };
		case AppActionTypes.DeleteAssignmentTemplateFailHandled:
			return state = { ...state, error: undefined };
		case AppActionTypes.DeleteAssignmenteTemplateSuccess:
			return state = { ...state, isAssignmentTemplatesDeleted: true };
		case AppActionTypes.OkDeletedAssignmentTemplate:
			return state = { ...state, isAssignmentTemplatesDeleted: false };
	}

	return state;
}

// Initial state
const initialFeedsState: IFeedState = {
	isAllLoaded: false,
	isFeedClone: false,
	feedId: -1,
	feeds: []
};

export function feedsReducer(state: IFeedState = initialFeedsState, action: AppActions): IFeedState {

	switch (action.type) {
		case AppActionTypes.LoadAllFeedsSuccess:
			return state = { ...state, isAllLoaded: true, feeds: action.feeds };
		case AppActionTypes.LoadAllFeedsFail:
			return state = { ...state, isAllLoaded: false };
		case AppActionTypes.CloneFeed:
			return state = { ...state, isFeedClone: true };
		case AppActionTypes.CloneFeedFail:
			return state = { ...state, isFeedClone: false };
		case AppActionTypes.CloneFeedSuccess:
			state = {
				...state,
				feeds: state.feeds.concat(action.feed),
				feedId: action.feed.id,
				isFeedClone: false
			};
			break;
		case AppActionTypes.ClearClonedFeed:
			state = { ...state, feedId: -1 };
	}

	return state;
}

const initialShardsState: IShardState = {
	isAllLoaded: false,
	shards: []
};

export function shardsReducer(state: IShardState = initialShardsState, action: AppActions): IShardState {

	switch (action.type) {
		case AppActionTypes.LoadShardsSuccess:
			return state = { ...state, isAllLoaded: true, shards: action.shards };
		case AppActionTypes.LoadLogsByFeedFail:
			return state = { ...state, isAllLoaded: false };
	}

	return state;
}

const initialFeedLogsState: IFeedLogsState = { feedLogs: [], isFeedsLogFetching: false };

export function feedLogsReducer(state: IFeedLogsState = initialFeedLogsState, action: AppActions): IFeedLogsState {
	switch (action.type) {
		case AppActionTypes.LoadLogsByFeed:
			return state = {
				...state,
				isFeedsLogFetching: true
			};
		case AppActionTypes.LoadLogsByFeedSuccess:
			return state = {
				...state,
				isFeedsLogFetching: false,
				feedLogs: state.feedLogs.concat({ feedId: action.feedId, feedLogs: action.feedLogs })
			};
		case AppActionTypes.LoadLogsByFeedFail:
			return state = { ...state, isFeedsLogFetching: false };
	}

	return state;
}

const initialFeedLogState: IFeedLogState = { isAllLoaded: false, feedLog: FeedLog, isFeedLogFetching: false };

export function feedLogReducer(state: IFeedLogState = initialFeedLogState, action: AppActions): IFeedLogState {
	switch (action.type) {
		case AppActionTypes.LoadFeedLog:
			return state = {
				...state,
				isFeedLogFetching: true
			};
		case AppActionTypes.LoadFeedLogSuccess:
			return state = {
				...state,
				isFeedLogFetching: false,
				feedLog: action.feedLog
			};
		case AppActionTypes.LoadFeedLogFail:
			return state = {
				...state,
				isFeedLogFetching: false
			};
	}

	return state;
}

const initialFeedFilesState: IFeedFilesState = { feedFiles: [], isFeedFilesFetching: false };

export function feedFilesReducer(state: IFeedFilesState = initialFeedFilesState, action: AppActions): IFeedFilesState {
	switch (action.type) {
		case AppActionTypes.LoadFilesByFeed:
			return state = {
				...state,
				isFeedFilesFetching: true
			};
		case AppActionTypes.LoadFilesByFeedSuccess:
			return state = {
				...state,
				isFeedFilesFetching: false,
				feedFiles: state.feedFiles.concat({ feedId: action.feedId, feedFiles: action.feedFiles })
			};
		case AppActionTypes.LoadFeedLogFail:
			return state = {
				...state,
				isFeedFilesFetching: false
			};
	}

	return state;
}

const initialFeedFileState: IFeedFileState = { isAllLoaded: false, feedFile: FeedFile, isFeedFileFetching: false };

export function feedFileReducer(state: IFeedFileState = initialFeedFileState, action: AppActions): IFeedFileState {
	switch (action.type) {

		case AppActionTypes.LoadFeedFile:
			return state = {
				...state,
				isFeedFileFetching: true
			};
		case AppActionTypes.LoadFeedFileSuccess:
			return state = {
				...state,
				feedFile: action.feedFile,
				isFeedFileFetching: false
			};
		case AppActionTypes.LoadFeedFileFail:
			return state = {
				...state,
				feedFile: action.feedFile,
				isFeedFileFetching: false
			};
	}

	return state;
}

const initialLoadingState: ILoadingState = { loadingStatuses: [] };

export function loadingsReducer(state: ILoadingState = initialLoadingState, action: ActionWithStatus): ILoadingState {
	switch (action.actionType) {
		case ActionType.Loading:
			return { ...state, loadingStatuses: state.loadingStatuses.concat(true) };
		case ActionType.LoadingEnded:
			return { ...state, loadingStatuses: state.loadingStatuses.slice(1) };
		default:
			return state;
	}
}

const initialAuthState: IAuthState = {
	user: null,
	loading: false,
	loaded: false,
	errorMessage: ''
};

export function authReducer(state: IAuthState = initialAuthState, action: AuthAction): IAuthState {
	switch (action.type) {
		case AuthActions.LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload,
				loading: false,
				loaded: true
			};
		case AuthActions.LOGIN_FAILURE: {
			return {
				...state,
				errorMessage: 'Incorrect username and/or password.'
			};
		}
		default:
			return state;
	}
}

// Initial state
const initialTemplatesState: ITemplatesState = {
	isAllLoaded: false,
	templateId: -1,
	templates: [],
	isNewTemplateCreating: false,
	isTemplatesDeleted: false,
	error: undefined
};

export function templatesReducer(state: ITemplatesState = initialTemplatesState, action: AppActions): ITemplatesState {
	switch (action.type) {
		case AppActionTypes.LoadAllTemplatesSuccess:
			return state = { ...state, isAllLoaded: true, templates: action.templates };
		case AppActionTypes.AddNewTemplate:
			return state = { ...state, isNewTemplateCreating: true };
		case AppActionTypes.AddTemplateFail:
			return state = { ...state, isNewTemplateCreating: false, error: action.error };
		case AppActionTypes.AddTemplateSuccess:
			return state = { ...state, isNewTemplateCreating: false, templateId: action.templateId };
		case AppActionTypes.ClearCreatedTemplate:
			return state = { ...state, templateId: -1 };
		case AppActionTypes.DeleteTemplatesFail:
			return state = { ...state, error: action.error };
		case AppActionTypes.DeleteTemplateFailHandled:
			return state = { ...state, error: undefined };
		case AppActionTypes.DeleteTemplatesSuccess:
			return state = { ...state, isTemplatesDeleted: true };
		case AppActionTypes.OkDeletedTemplate:
			return state = { ...state, isTemplatesDeleted: false };
		case AppActionTypes.UpdateTemplateFail:
			return state = { ...state, isNewTemplateCreating: false, error: action.error };
	}
	return state;
}

const initialTemplateDetailsState: ITemplateDetailsState = {
	isAllLoaded: false,
	template: undefined,
	isTemplateFetching: false,
	isTemplateUpdated: false
};

export function templateDetailsReducer(state: ITemplateDetailsState = initialTemplateDetailsState, action: AppActions): ITemplateDetailsState {
	switch (action.type) {
		case AppActionTypes.LoadTemplate:
			return state = {
				...state,
				isAllLoaded: false
			};
		case AppActionTypes.LoadTemplateSuccess:
			return state = {
				...state,
				isAllLoaded: true,
				template: action.template
			};
		case AppActionTypes.LoadTemplateFail:
			return state = {
				...state,
				isAllLoaded: false
			};
		case AppActionTypes.ClearTemplate:
			return state = initialTemplateDetailsState;
		case AppActionTypes.UpdateTemplateSuccess:
			return state = { ...state, isTemplateUpdated: true };
	}
	return state;
}

const initialAssignmentTemplateDetailsState: IAssignmentTemplateDetailsState = {
	isAllLoaded: false,
	template: undefined,
	isTemplateFetching: false,
	isTemplateUpdated: false
};

export function assignmentTemplateDetailsReducer(state: IAssignmentTemplateDetailsState = initialAssignmentTemplateDetailsState, action: AppActions): IAssignmentTemplateDetailsState {
	switch (action.type) {
		case AppActionTypes.LoadAssignmentTemplate:
			return state = {
				...state,
				isAllLoaded: false
			};
		case AppActionTypes.LoadAssignmentTemplateSuccess:
			return state = {
				...state,
				isAllLoaded: true,
				template: action.template
			};
		case AppActionTypes.LoadAssignmentTemplateFail:
			return state = {
				...state,
				isAllLoaded: false
			};
		case AppActionTypes.ClearAssignmentTemplate:
			return state = initialAssignmentTemplateDetailsState;
		case AppActionTypes.UpdateAssignmentTemplateSuccess:
			return state = { ...state, isTemplateUpdated: true };
	}
	return state;
}

// TODO: Refactor ngrx store implementaion, current imlementation is no longer supported and types are incompatible.
//       <any> left as a workaround for ts strict mode to work.
export const reducers: ActionReducerMap<IAppState> = <any>{

	brandsState: brandsReducer,
	feedsState: feedsReducer,
	feedLogsState: feedLogsReducer,
	shardsState: shardsReducer,
	feedLogState: feedLogReducer,
	feedFilesState: feedFilesReducer,
	feedFileState: feedFileReducer,
	brandUsersState: brandUsersReducer,
	loadingsState: loadingsReducer,
	brandDetailsState: brandDetailsReducer,
	auth: authReducer,
	templatesState: templatesReducer,
	templateDetailsState: templateDetailsReducer,
	assignmentTemplatesState: assignmentTemplatesReducer,
	assignmentTemplateDetailsState: assignmentTemplateDetailsReducer,
	templateAssignmentsState: templateAssignmentsReducer,
};
