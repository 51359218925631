export enum RdlUrlType {
	RdlInvoiceUrlType = 1,
	RdlReportUrlType = 2,
}

export class RdlTemplateUrlParser {

	public static parseType(url: string): RdlUrlType {
		const segmentArr = url.split('/');
		switch (segmentArr[1]) {
			case 'brands':
				switch (segmentArr[3]) {
					case 'invoice-templates':
						return RdlUrlType.RdlInvoiceUrlType;
					case 'report-templates':
						return RdlUrlType.RdlReportUrlType;
				}
				break;
			case 'templates':
				switch (segmentArr[2]) {
					case 'rdl-invoice-templates':
						return RdlUrlType.RdlInvoiceUrlType;
					case 'rdl-report-templates':
						return RdlUrlType.RdlReportUrlType;
				}
		}

		throw Error('RdlTemplateUrlParser: failed parsing ' + url);
	}
}

export enum AssignmentUrlType {
	InvoiceUrlType= 1,
	ReportUrlType = 2
}

export class AssignmentUrlParser {

	public static parseType(url: string): AssignmentUrlType {
		const segmentArr = url.split('/');
		switch (segmentArr[3]) {
			case 'invoice-templates':
				return AssignmentUrlType.InvoiceUrlType;
			case 'report-templates':
				return AssignmentUrlType.ReportUrlType;
		}

		throw Error('AssignmentUrlParser: failed parsing ' + url);
	}
}
