import { Action } from '@ngrx/store';

export enum ActionType {
	Loading,
	LoadingEnded
}

export abstract class ActionWithStatus implements Action {
	type: string;
	actionType: ActionType;
	brands: any;
	brand: any;
	newBrand: any;
	brandId: any;
	feed: any;
	feedLog: any;
	feedFile: any;
	feedId: any;
	feeds: any;
	shards: any;
	brandUsers: any;
	token: any;
	templates: any;
	template: any;
	newTemplate: any;
	templateId: any;
	assignmentTemplates: any;
	assignmentTemplateId: any;
	error: any;
	templateAssignments: any;
}

export abstract class FlaxActions implements Action {
	type: string;
	brands: any;
	brand: any;
	newBrand: any;
	brandId: any;
	feed: any;
	feeds: any;
	shards: any;
	feedId: any;
	brandUsers: any;
	token: any;
	templates: any;
	template: any;
	newTemplate: any;
	templateId: any;
	assignmentTemplates: any;
	assignmentTemplateId: any;
	error: any;
	templateAssignments: any;
}

export class FlaxAction implements FlaxActions {
	type: string;
	brands: any;
	brand: any;
	newBrand: any;
	brandId: any;
	feed: any;
	feeds: any;
	shards: any;
	feedLogs: any;
	feedId: any;
	brandUsers: any;
	feedFiles: any;
	feedLog: any;
	feedFile: any;
	token: any;
	templates: any;
	template: any;
	newTemplate: any;
	templateId: any;
	assignmentTemplates: any;
	assignmentTemplateId: any;
	error: any;
	templateAssignments: any;
}

export class ActionWithLoading implements ActionWithStatus {
	type: string;
	actionType: ActionType = ActionType.Loading;
	brands: any;
	brand: any;
	newBrand: any;
	brandId: any;
	feed: any;
	feeds: any;
	shards: any;
	feedLogs: any;
	feedFiles: any;
	feedLog: any;
	feedFile: any;
	feedId: any;
	brandUsers: any;
	token: any;
	templates: any;
	template: any;
	newTemplate: any;
	templateId: any;
	assignmentTemplates: any;
	assignmentTemplateId: any;
	error: any;
	templateAssignments: any;
}

export class ActionWithLoadingEnded implements ActionWithStatus {
	type: string;
	actionType: ActionType = ActionType.LoadingEnded;
	brands: any;
	brand: any;
	newBrand: any;
	brandId: any;
	feed: any;
	feeds: any;
	shards: any;
	feedLogs: any;
	feedFiles: any;
	feedLog: any;
	feedFile: any;
	feedId: any;
	brandUsers: any;
	token: any;
	templates: any;
	template: any;
	newTemplate: any;
	templateId: any;
	assignmentTemplates: any;
	assignmentTemplateId: any;
	error: any;
	templateAssignments: any;
}
