import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import * as authActions from '../../store/actions/auth-actions';
import * as appActions from '../../store/actions/admin-actions';
import {
	DeleteAssignmentTemplate,
	DeleteTemplates,
	SetBrandStatus,
	UpdateBrand,
	UpdateAssignmentTemplate,
	UpdateTemplate
} from '../actions/admin-actions';

import { AdminAuthService } from 'core/auth';
import * as fromApp from '../admin-states';
import { IAppState } from '../reducers/app.reducer';
import { AssignmentTemplateAddResponse, Template, TemplateAddResponse } from 'domain/models';
import { AdminFeedsRepository, BrandsRepository, TemplatesRepository } from '../../common/repositories';

@Injectable()
export class AdminEffects {

	constructor(
		private readonly actions$: Actions,
		private readonly brandsRepo: BrandsRepository,
		private readonly feedsRepo: AdminFeedsRepository,
		private readonly authService: AdminAuthService,
		private readonly store: Store<IAppState>,
		private readonly templatesRepo: TemplatesRepository
	) {
	}

	logIn$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(authActions.AuthActions.LOGIN),
		switchMap((action: authActions.LogIn) =>
			this.authService.adminLogin(action.payload.userName, action.payload.password).pipe(
				map(user => new authActions.LogInSuccess(user)),
				catchError(res => of(new authActions.LogInFailure({ error: res })))
			)
		)
	));

	getAllBrands$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadAllBrands),
		withLatestFrom(this.store.select(fromApp.isAllBrandsLoaded)),
		mergeMap(([_, isLoaded]) =>
			isLoaded
				? of(new appActions.SliceLoading())
				: this.brandsRepo.getAll().pipe(
					map(brands => new appActions.LoadAllBrandsSuccess(brands)),
					catchError((err) => of(new appActions.LoadAllBrandsFail(err)))
				)
		)
	));

	getAllTemplates$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(appActions.AppActionTypes.LoadAllTemplates),
			switchMap((x: appActions.LoadAllTemplates) => {
				return this.templatesRepo.getAll(x.tplType).pipe(
					map((templates: Template[]) => new appActions.LoadAllTemplatesSuccess(templates)),
					catchError(() => of(new appActions.LoadAllTemplatesFail('')))
				);
			}
			)

			//TODO Figure out how to get x.tplType and use withLatestFrom and mergeMap:

			// ofType(appActions.AppActionTypes.LoadAllTemplates),
			// withLatestFrom(this.store.select(fromApp.isAllTemplatesLoaded)),
			// mergeMap(([_, isLoaded]) =>
			// 	isLoaded
			// 		? of(new appActions.SliceLoading())
			// 		: this.templatesRepo.getAll().pipe(
			// 			map((templates: Template[]) => new appActions.LoadAllTemplatesSuccess(templates)),
			// 			catchError(() => of(new appActions.LoadAllTemplatesFail('')))
			// 		)
			// )
		);
	});

	createTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.AddNewTemplate),
		switchMap((x: appActions.AddNewTemplate) =>
			this.templatesRepo.addTemplate(x.template).pipe(
				map((templateAddResponse: TemplateAddResponse) => new appActions.AddTemplateSuccess(templateAddResponse.templateId)),
				catchError((error) => of(new appActions.AddTemplateFail(error)))
			))
	));

	deleteTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.DeleteTemplates),
		switchMap((x: DeleteTemplates) =>
			this.templatesRepo.deleteTemplates(x.templatesDelete, x.urlType).pipe(
				map(() => new appActions.DeleteTemplatesSuccess()),
				catchError((error) => of(new appActions.DeleteTemplatesFail(error)))
			))
	));

	addAssignmentTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.AddAssignmentTemplate),
		switchMap((x: appActions.AddAssignmentTemplate) =>
			this.templatesRepo.addAssignmentTemplate(x.bindAssignmentTemplate, x.assignmentUrlType).pipe(
				map((newTemplate: AssignmentTemplateAddResponse) => new appActions.AddAssignmentTemplateSuccess(newTemplate.assignmentTemplateId)),
				catchError(() => of(new appActions.AddAssignmentTemplateFail('')))
			))
	));

	deleteAssignmentTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.DeleteAssignmentTemplate),
		switchMap((x: DeleteAssignmentTemplate) =>
			this.templatesRepo.deleteAssignmentTemplate(x.assignmentTemplatesDelete, x.assignmentUrlType).pipe(
				map((newTemplate: any) => new appActions.DeleteAssignmentTemplateSuccess()),
				catchError((error) => of(new appActions.AssignmentAssignmentTemplateFail(error)))
			))
	));


	getTemplateDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadTemplate),
		switchMap((x: appActions.LoadTemplate) =>
			this.templatesRepo.getTemplateById(x.templateId).pipe(
				map(template => new appActions.LoadTemplateSuccess(template)),
				catchError((err) => of(new appActions.LoadTemplateFail(err.message)))
			)
		)
	));

	getAssignmentTemplateDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadAssignmentTemplate),
		switchMap((x: appActions.LoadAssignmentTemplate) =>
			this.templatesRepo.getAssignmentTemplateById(x.brandId, x.templateId, x.urlType).pipe(
				map(template => new appActions.LoadAssignmentTemplateSuccess(template)),
				catchError((err) => of(new appActions.LoadAssignmentTemplateFail(err.message)))
			)
		)
	));

	getTemplateAssignments$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadTemplateAssignments),
		switchMap((x: appActions.LoadTemplateAssignments) =>
			this.templatesRepo.getTemplateAssignmentsById(x.templateId, x.urlType).pipe(
				map(template => new appActions.LoadTemplateAssignmentsSuccess(template)),
				catchError((err) => of(new appActions.LoadTemplateAssignmentsFail(err.message)))
			)
		)
	));

	updateTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.UpdateTemplate),
		switchMap((x: UpdateTemplate) =>
			this.templatesRepo.updateTemplate(x.updateTemplateData).pipe(
				map((newTemplate: any) => new appActions.UpdateTemplateSuccess(x.updateTemplateData)),
				catchError((error) => of(new appActions.UpdateTemplateFail(error)))
			))
	));

	updateAssignmentTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.UpdateAssignmentTemplate),
		switchMap((x: UpdateAssignmentTemplate) =>
			this.templatesRepo.updateAssignmentTemplate(x.assignmentTemplateUpdate, x.assignmentUrlType).pipe(
				map((newTemplate: any) => new appActions.UpdateAssignmentTemplateSuccess()),
				catchError((err) => of(new appActions.UpdateAssignmentTemplateFail(err.message)))
			))
	));

	getBrandDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadBrand),
		switchMap((x: appActions.LoadBrand) =>
			this.brandsRepo.getBrandById(x.brandId).pipe(
				map(brand => new appActions.LoadBrandSuccess(brand)),
				catchError((err) => of(new appActions.LoadBrandFail(err.message)))
			)
		)
	));

	createBrand$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.ProvisionNewBrand),
		switchMap((x: appActions.ProvisionNewBrand) =>
			this.brandsRepo.createReseller(x.brand).pipe(
				map(newBrand => new appActions.ProvisionBrandSuccess(x.brand, newBrand)),
				catchError(() => of(new appActions.ProvisionBrandFail('')))
			))
	));

	updateBrand$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.UpdateBrand),
		switchMap((x: UpdateBrand) =>
			this.brandsRepo.updateReseller(x.updateBrandData, x.brandId).pipe(
				map(newBrand => new appActions.UpdateBrandSuccess(x.updateBrandData)),
				catchError(() => of(new appActions.UpdateBrandFail(x.updateBrandData)))
			))
	));

	setBrandStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.SetBrandStatus),
		switchMap((x: SetBrandStatus) =>
			this.brandsRepo.setBrandStatus(x.brandStatusUpdateData, x.brandId).pipe(
				map(newBrand => new appActions.SetBrandStatusSuccess(x.brandStatusUpdateData, x.brandId)),
				catchError(() => of(new appActions.SetBrandStatusFail(x.brandStatusUpdateData, x.brandId)))
			))
	));

	getUsersByBrand$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadUsersByBrand),
		switchMap((x: any) =>
			this.brandsRepo.getUsersById(x.id).pipe(
				map(brandUsers => new appActions.LoadUsersByBrandSuccess(brandUsers, parseInt(x.id))),
				catchError(() => of(new appActions.LoadUsersByBrandFail('')))
			)
		)
	));

	getAssignmentTemplatesByBrand$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadAssignmentTemplates),
		switchMap((x: appActions.LoadAssignmentTemplates) =>
			this.templatesRepo.getAssignmentTemplatesByBrand(x.brandId, x.urlType).pipe(
				map(assignmentTemplates => new appActions.LoadAssignmentTemplatesSuccess(assignmentTemplates)),
				catchError(() => of(new appActions.LoadAssignmentTemplatesFail('')))
			)
		)
	));

	getShards$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadShards),
		withLatestFrom(this.store.select(fromApp.isShardsLoaded)),
		mergeMap(([_, isLoaded]) =>
			isLoaded
				? of(new appActions.SliceLoading())
				: this.brandsRepo.getAllshards().pipe(
					map(shards => new appActions.LoadShardsSuccess(shards)),
					catchError(() => of(new appActions.LoadShardsFail('')))
				)
		)
	));

	getAllFeeds$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadAllFeeds),
		withLatestFrom(this.store.select(fromApp.isAllFeedsLoaded)),
		mergeMap(([_, isLoaded]) =>
			isLoaded
				? of(new appActions.SliceLoading())
				: this.feedsRepo.getAll().pipe(
					map(feeds => new appActions.LoadAllFeedsSuccess(feeds)),
					catchError(() => of(new appActions.LoadAllFeedsFail('')))
				)
		)
	));

	getLogsByFeed$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadLogsByFeed),
		switchMap((x: any) =>
			this.feedsRepo.getFeedLogs(x.id).pipe(
				map(feedLogs => new appActions.LoadLogsByFeedSuccess(feedLogs, parseInt(x.id))),
				catchError(() => of(new appActions.LoadLogsByFeedFail('')))
			)
		)
	));

	getFeedLog$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadFeedLog),
		switchMap((x: appActions.LoadFeedLog) =>
			this.feedsRepo.getFeedLog(x.feedId, x.logId).pipe(
				map(feedLog => new appActions.LoadFeedLogSuccess(feedLog)),
				catchError((err) => of(new appActions.LoadFeedLogFail(err.message)))
			)
		)
	));

	getFilesByFeed$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadFilesByFeed),
		switchMap((x: any) =>
			this.feedsRepo.getFeedFiles(x.id).pipe(
				map(feedFiles => new appActions.LoadFilesByFeedSuccess(feedFiles, parseInt(x.id))),
				catchError(() => of(new appActions.LoadFilesByFeedFail('')))
			)
		)
	));

	getFeedFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.LoadFeedFile),
		switchMap((x: appActions.LoadFeedFile) =>
			this.feedsRepo.getFeedFile(x.feedId, x.fileId).pipe(
				map(feedFile => new appActions.LoadFeedFileSuccess(feedFile!)),
				catchError((err) => of(new appActions.LoadFeedFileFail(err.message)))
			)
		)
	));

	cloneFeed$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(appActions.AppActionTypes.CloneFeed),
		switchMap((f: appActions.CloneFeed) =>
			this.feedsRepo.cloneFeed(f.feedId, f.feed).pipe(
				map(() => new appActions.CloneFeedSuccess(f.feed)),
				catchError(() => of(new appActions.CloneFeedFail('')))
			))
	));
}
