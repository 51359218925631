import { IIdentity } from './lookup';
import { Tag } from './tags';
import { Guid } from '../types';

export class Brand implements IIdentity {
	id: number;
	name: string;
	resellerId: number;
	databaseName: string;
	useProductChargesHistoricity: boolean;
	allowMultiplePaymentPortals: boolean;
	useAutoBilling: boolean;
	kleberIntegrationCountry: string;
	active: boolean;
	url: string;
	isLive: boolean;
	tags: Tag[];
	archiveInvoicedPeriods: number;
	distributorId: number;
	distributorName: string;
	distributorCustomerId: Guid | undefined;
	isDistributor: boolean;
}

export class BrandUsers implements IIdentity {
	id: number;
	userId: number;
	brandId: any;
	userName: string;
	displayName: string;
	contactPhone: string;
	contactEmail: string;
	brandUsers: any;
}

export class ProvisionBrand {
	brandName: string;
	userName: string;
	name: string;
	email: string;
	monthsOffSet: number;
	password: string;
	provisionTemplateId: number;
	useProductChargesHistoricity = false;
	allowMultiplePaymentPortals = false;
	useAutoBilling = false;
	kleberIntegrationCountry: string;
	kleberIntegration = false;
	shardId: number;
	isLive: boolean;
	archiveInvoicedPeriods: number;
	distributorId: number;
	distributorCustomerId: undefined;

	constructor() {
		this.monthsOffSet = -1;
	}

}

export class UpdateBrandData {
	kleberIntegrationCountry: string;
	useProductChargesHistoricity: boolean;
	allowMultiplePaymentPortals: boolean;
	useAutoBilling: boolean;
	url: string;
	isLive: boolean;
	archiveInvoicedPeriods: number;
	distributorId: number;
	distributorCustomerId: Guid | undefined;
}

export class BrandStatusUpdateData {
	active: boolean;
}

export class ProvisionValidate {
	field: string;
	value: string;
}

export class Shard {
	id: number;
	name: string;
}

export const kleberCountryItems: string[] = ['INT', 'AU', 'NZ', 'CA', 'UK'];

export interface ArchiveInvoicedPeriod {
	name: string;
	value: number;
}
