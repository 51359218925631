import { Injectable } from '@angular/core';
import { HttpAdminAuth } from 'core/auth';
import { AppConfig } from 'tools/AppConfig';
import { Observable } from 'rxjs';
import {
	BindAssignmentTemplate,
	AssignmentTemplate,
	AssignmentTemplateAddResponse,
	AssignmentTemplatesDelete, AssignmentTemplateUpdate,
	Template,
	TemplateAddResponse, TemplateAssignment, TemplatesDelete
} from 'domain/models';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { ApiData, Guid } from 'domain/types';
import { AssignmentUrlType, RdlUrlType } from '../../templates/utils/utils';

@Injectable()
export class TemplatesRepository {

	constructor(
		private http: HttpAdminAuth,
		private config: AppConfig
	) {
	}

	getAll(tplType: RdlUrlType): Observable<Template[]> {
		const requestOptions = {};
		let urlSegment: string;
		switch (tplType) {
			case RdlUrlType.RdlInvoiceUrlType: urlSegment = 'templates';
				break;
			case RdlUrlType.RdlReportUrlType: urlSegment = 'report_templates';
				break;
		}

		return this.http.get<ApiData<Template[]>>(`${this.config.apiUrl}/admin/${urlSegment}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? []),
			);
	}

	addTemplate(template: Template): Observable<TemplateAddResponse> {
		let resp: TemplateAddResponse;

		return this.http.post<ApiData<TemplateAddResponse>>(`${this.config.apiUrl}/admin/template`, template, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body?.data ?? resp),
			);
	}

	getTemplateById(id: number): Observable<Template> {
		const requestOptions = {};

		let template: Template;

		return this.http.get<ApiData<Template>>(`${this.config.apiUrl}/admin/templates/${id}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? template),
			);
	}

	getTemplateAssignmentsById(id: number, urlType: RdlUrlType): Observable<TemplateAssignment[]> {
		const requestOptions = {};

		let urlSegment: string;
		switch (urlType) {
			case RdlUrlType.RdlInvoiceUrlType: urlSegment = 'invoice';
				break;
			case RdlUrlType.RdlReportUrlType: urlSegment = 'report';
				break;
		}

		return this.http.get<ApiData<TemplateAssignment[]>>(`${this.config.apiUrl}/admin/${urlSegment}-assignments/${id}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? []),
			);
	}

	getAssignmentTemplateById(brandId: number, id: Guid, templateId: AssignmentUrlType): Observable<AssignmentTemplate | undefined> {
		return this.getAssignmentTemplatesByBrand(brandId, templateId).pipe(map(template => template.find(x => x.id.equals(id))));
	}

	updateTemplate(data: Template): Observable<void> {
		return this.http.put(`${this.config.apiUrl}/admin/templates/${data.id}`, data, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	updateAssignmentTemplate(assignmentTemplateUpdate: AssignmentTemplateUpdate, assignmentUrlType: AssignmentUrlType): Observable<void> {

		let urlSegment: string;

		switch (assignmentUrlType) {
			case AssignmentUrlType.InvoiceUrlType:
				urlSegment = 'invoice-templates';
				break;
			case AssignmentUrlType.ReportUrlType:
				urlSegment = 'report-template';
				break;
		}

		return this.http.put(`${this.config.apiUrl}/admin/${urlSegment}/${assignmentTemplateUpdate.templateId}`,
			assignmentTemplateUpdate, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	getAssignmentTemplatesByBrand(brandId: number, urlType: AssignmentUrlType): Observable<AssignmentTemplate[]> {
		const requestOptions = {};

		let urlSegment: string;

		switch (urlType) {
			case AssignmentUrlType.InvoiceUrlType:
				urlSegment = 'invoice-templates';
				break;
			case AssignmentUrlType.ReportUrlType:
				urlSegment = 'report-templates';
				break;
		}

		return this.http.get<ApiData<AssignmentTemplate[]>>(`${this.config.apiUrl}/admin/${urlSegment}/${brandId}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? []),
			);
	}

	addAssignmentTemplate(template: BindAssignmentTemplate, assignmentUrlType: AssignmentUrlType): Observable<AssignmentTemplateAddResponse> {
		let resp: AssignmentTemplateAddResponse;

		let urlSegment: string;

		switch (assignmentUrlType) {
			case AssignmentUrlType.InvoiceUrlType:
				urlSegment = 'invoice-template';
				break;
			case AssignmentUrlType.ReportUrlType:
				urlSegment = 'report-template';
				break;
		}

		return this.http.post<ApiData<AssignmentTemplateAddResponse>>(`${this.config.apiUrl}/admin/${urlSegment}`, template, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body?.data ?? resp),
			);
	}

	deleteAssignmentTemplate(assignmentTemplatesDelete: AssignmentTemplatesDelete, assignmentUrlType: AssignmentUrlType): Observable<any> {

		let urlSegment: string;

		switch (assignmentUrlType) {
			case AssignmentUrlType.InvoiceUrlType:
				urlSegment = 'invoice-templates';
				break;
			case AssignmentUrlType.ReportUrlType:
				urlSegment = 'report-templates';
				break;
		}

		return this.http.post(`${this.config.apiUrl}/admin/${urlSegment}/delete`, assignmentTemplatesDelete, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	deleteTemplates(templatesDelete: TemplatesDelete, urlType: RdlUrlType): Observable<any> {

		let urlSegment: string;

		switch (urlType) {
			case RdlUrlType.RdlInvoiceUrlType:
				urlSegment = 'rdl-invoice-templates';
				break;
			case RdlUrlType.RdlReportUrlType:
				urlSegment = 'rdl-report-templates';
				break;
		}

		return this.http.post(`${this.config.apiUrl}/admin/${urlSegment}/delete`, templatesDelete, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	static headersToOptions(): { headers: HttpHeaders; } {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return { headers: headers };
	}

}
