import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brand, BrandUsers, ProvisionValidate, ProvisionBrand, Shard, UpdateBrandData, BrandStatusUpdateData } from 'domain/models';
import { HttpAdminAuth } from 'core/auth';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'tools/AppConfig';
import { ApiData } from 'domain/types';
import { Tag } from 'domain/models/tags';
import { CustomerDetails } from '../../../domain/models/customers';

interface RequestOptions {
	headers: HttpHeaders;
}

@Injectable()
export class BrandsRepository {

	constructor(
		private http: HttpAdminAuth,
		private config: AppConfig
	) {
	}

	getAll(): Observable<Brand[]> {
		return this.http.get<ApiData<Brand[]>>(`${this.config.apiUrl}/admin/brands`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	getAllshards(): Observable<Shard[]> {
		return this.http.get(`${this.config.apiUrl}/admin/shards`)
			.pipe(
				map(res => res.body)
			);
	}

	getBrandById(id: number): Observable<Brand | undefined> {
		return this.getAll().pipe(map(brand => brand.find(x => x.id === id)));
	}

	getUsersById(brandId: number): Observable<BrandUsers[]> {
		return this.http.get<ApiData<BrandUsers[]>>(`${this.config.apiUrl}/admin/brand-users/${brandId}`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	public async loginAsResellerUser(userId: number): Promise<string | undefined> {
		return lastValueFrom(this.http.get(`${this.config.apiUrl}/admin/portal/${userId}/loginas`)
			.pipe(
				map(res => {
					return res.body.data.toString();
				})
			), { defaultValue: undefined });
	}

	public adminProvisionValidate(value: ProvisionValidate[]): Observable<any> {
		return this.http.post(`${this.config.apiUrl}/admin/provision/validate`, { records: value }, BrandsRepository.headersToOptions())
			.pipe(
				map(res => res.body)
			);
	}

	public createReseller(brand: ProvisionBrand): Observable<any> {
		return this.http.post(`${this.config.apiUrl}/admin/provision`, brand, BrandsRepository.headersToOptions())
			.pipe(
				map(res => res.body)
			);
	}

	public updateReseller(updateBrandData: UpdateBrandData, brandId: number): Observable<any> {
		return this.http.put(`${this.config.apiUrl}/admin/brand/${brandId}`, updateBrandData, BrandsRepository.headersToOptions())
			.pipe(
				map(res => res.body)
			);
	}

	public setBrandStatus(brandStatusUpdateData: BrandStatusUpdateData, brandId: number): Observable<any> {
		return this.http.put(`${this.config.apiUrl}/admin/brand/${brandId}/status`, brandStatusUpdateData, BrandsRepository.headersToOptions());
	}

	private static headersToOptions(): RequestOptions {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return { headers: headers };
	}

	async getBrandTags(brandId: number): Promise<Tag[]> {
		const tagBrands = await this.http.promise().get<ApiData<Tag[]>>(`${this.config.apiUrl}/admin/brands/${brandId}/tags`);
		return tagBrands.body?.data || [];
	}

	public async setBrandTag(brandId: number, tags: Tag[]): Promise<void> {
		const res = await this.http.promise().post(`${this.config.apiUrl}/admin/brands/${brandId}/tags`, tags);
		return res.body;
	}

	async getBrandCustomers(brandId: number): Promise<CustomerDetails[]> {
		const customers = await this.http.promise().get<ApiData<CustomerDetails[]>>(`${this.config.apiUrl}/admin/brands/${brandId}/customers`);
		return customers.body?.data || [];
	}

	async getAllBrands(): Promise<Brand[]> {
		const brands = await this.http.promise().get<ApiData<Brand[]>>(`${this.config.apiUrl}/admin/brands`);
		return brands.body?.data || [];
	}
}
