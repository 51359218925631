import { Guid } from '../types';

export interface Template {
	id: number;
	name: string;
	description: string;
	created: string;
	createdBy: string;
	modified: string;
	modifiedBy: string;
	rdl: string;
	type: string;
}

export interface TemplateAddResponse {
	templateId: number;
}

export interface AssignmentTemplate {
	id: Guid;
	name: string;
	description: string;
	dateAssigned: string;
	type: string;
	isDefault: boolean;
	catalogTemplateId: number;
	delimiter: string;
	exportTypeId: number;
	isResellerReport: boolean;
	parameters: BindAssignmentParams[];
	customerId: Guid;
}

export interface BindAssignmentTemplate {
	catalogTemplateId: number;
	brandId: number;
	isDefault: boolean;
	delimiter: string;
	exportTypeId: number;
	isResellerReport: boolean;
	parameters: BindAssignmentParams[];
}

export interface BindAssignmentParams {
	id: Guid;
	name: string;
	type: string;
	readonly: boolean;
}

export interface AssignmentTemplateUpdate {
	isDefault: boolean;
	brandId: number;
	templateId: Guid;
	delimiter: string;
	exportTypeId: number;
	isResellerReport: boolean;
	parameters: BindAssignmentParams[];
	customerId: Guid;
}

export interface AssignmentTemplateAddResponse {
	assignmentTemplateId: Guid;
}

export interface AssignmentTemplatesDelete {
	assignmentTemplatesIds: Guid[];
	brandId: number;
}

export interface TemplatesDelete {
	templatesIds: number[];
}

export interface TemplateAssignment {
	brandId: number;
	brandName: string;
	assignmentDate: string;
}
